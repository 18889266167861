<template>
  <div class="bg-light min-vh-100 d-flex flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol :md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CIcon class="logo" size="9xl" :icon="icon"/>
                <h1 v-if="isEnterLabelVisible" class="text-center">Вход</h1>
                <p class="text-medium-emphasis">Пожалуйста, войдите в свой аккаунт</p>
                <CInputGroup class="mb-3">
                  <CInputGroupText>
                    <CIcon icon="cil-user"/>
                  </CInputGroupText>
                  <CFormInput
                    :value="login"
                    @input="login = $event.target.value"

                    placeholder="Логин"
                    autoComplete="username"
                  />
                  <CFormFeedback invalid>More example invalid feedback text</CFormFeedback>
                </CInputGroup>
                <CInputGroup class="mb-4">
                  <CInputGroupText>
                    <CIcon icon="cil-lock-locked"/>
                  </CInputGroupText>
                  <CFormInput
                    :value="password"
                    @input="password = $event.target.value"

                    type="password"
                    placeholder="Пароль"
                    autoComplete="current-password"
                  />
                </CInputGroup>
                <div class="login-footer">

                  <CButton color="primary" class="px-4 end-0" @click="auth"> Войти</CButton>

                </div>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import {sygnet} from '@/assets/brand/sygnet'
import {logoSquareSmsNegative} from '@/assets/brand/logo-square-sms-negative'


export default {
  name: 'Login',
  data: () => {
    return {
      login: '',
      password: '',
    }
  },
  setup() {
    const icon = process.env.VUE_APP_LOGO === 'logo-sms' ? logoSquareSmsNegative : sygnet
    const isEnterLabelVisible = process.env.VUE_APP_NAME !== 'asms'
    return {
      icon,
      isEnterLabelVisible
    }
  },
  mounted() {
    if (window.location.href.indexOf("?") === -1) {
      // this.login="test"
      // this.password="test"
      // this.auth()
    }
  },
  methods: {
    validateLogin() {
      if (this.login.indexOf('@') >= 0) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.login)) {
          console.log('Please enter a valid login')
          this.msg['email'] = 'Please enter a valid email address'
        }
      } else {
        if (/^\w+([\.-]?\w+)*$/.test(this.login)) {
          console.log('Please enter a valid login')
          this.msg['email'] = 'Please enter a valid email address'
        }
      }
    },
    auth() {

      this.$store.dispatch("auth", {
        login: this.login,
        password: this.password
      }).then(response => {
        if (response.data[0].meta.success) {
          this.$router.push('/')
        } else {
          this.$toast.error(response.data[0].result.message)
        }
      }).catch(e => {
        if (e.response.status === 401) {
          this.$toast.error("Неверный логин или пароль")
        } else {
          this.$toast.error(e.toLocaleString())
        }
      })
    }
  }
}
</script>
<style scoped>
.logo {
  display: block;
  margin: auto;
}

.login-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
</style>
